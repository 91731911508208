/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 17:40:13
 * @Module: 主播列表
 */
 <template>
  <div>
    <div v-show="showList">
      <div class="header">
        <h6>主播列表</h6>
        <div class="btn-wrap">
          <p class="btn"
             @click="addClick"
             v-role="['administrator','broker','manager']">
            <i class="el-icon-plus btn"></i>
            <span>新增</span>
          </p>
          <p class="btn"
             @click="exportClick">
            <i class="icon iconfont icon-daochu btn"></i>
            <span>导出</span>
          </p>
        </div>
      </div>
      <el-card style="margin-top:20px">
        <el-row :gutter="20">
          <el-form :inline="true"
                   :size="'small'"
                   :model="formInline"
                   class="demo-form-inline">
            <el-col :span="6">
              <el-form-item label="快手号:">
                <el-input v-model="formInline.tick_account"
                          placeholder="请输入要查询的快手号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6"
                    v-role="['administrator']">
              <el-form-item label="归属人:">
                <belonger @belongerSeChange="belongerSeChange"></belonger>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="快手ID:">
                <el-input v-model="formInline.tick_number"
                          placeholder="请输入要查询的快手ID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="经纪人:">
                <brokerSelect @selectTreeChange="selectTreeChange"
                              @clearBrokerSelect="clearBrokerSelect"
                              placeholder="请选择经纪人"></brokerSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="主播昵称:">
                <el-input v-model="formInline.nick_name"
                          placeholder="请输入要查询的主播昵称"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item>
                <el-button type="primary"
                           icon="el-icon-search"
                           @click="req_list(true)">搜索</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <tablePage :tableData="tableData"
                   :currentPage="formInline.page"
                   :height="tableHeight"
                   ref="tableList"
                   @current-change="currentChange"
                   v-loading="loading"
                   :total="total"
                   id="table">
          <el-table-column label="主播信息"
                           min-width="300">
            <template slot-scope="scope">
              <div class="base-wrap">
                <el-avatar :size="64"
                           :src="scope.row.avatar"></el-avatar>
                <div>
                  <p>
                    <span>{{ scope.row.nick_name }}</span>
                    <el-tag v-if="scope.row.origin==1"
                            type="warning"
                            size="mini"
                            style="margin-left:10px;">星探推荐</el-tag>
                  </p>
                  <p>快手号：{{ scope.row.tick_account }}</p>
                  <p>快手ID：{{ scope.row.tick_id }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="联系信息"
                           min-width="180">
            <template slot-scope="scope">
              <el-tag size="mini"
                      v-if="scope.row.is_increment==1"
                      style="color:#FF2C38;border-color:background:rgba(255,44,56,.1)"
                      color="background:rgba(255,44,56,.1)">拉新主播</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.is_increment==2"
                      style="color:#509DED;border-color:rgba(80,157,237,.1)"
                      color="rgba(80,157,237,.1)">存量主播</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.is_increment==3"
                      style="color:#FAB000;border-color:#fdf7e7"
                      color="#fdf7e7">委培主播</el-tag>
              <p>真实姓名：{{ scope.row.name }}</p>
              <p>电话：{{scope.row.phone}}</p>
            </template>
          </el-table-column>
          <el-table-column label="签约信息"
                           min-width="180">
            <template slot-scope="scope">
              <p>公会：{{ scope.row.mcn }}</p>
              <p>经纪人：{{ scope.row.broker_name }}</p>
              <p>分成比：{{scope.row.separate_ratio}}%</p>
            </template>
          </el-table-column>

          <el-table-column label="是否有效主播"
                           min-width="130">
            <template slot-scope="scope">
              <p>{{ scope.row.is_effective_anchor==0?"否":"是" }}</p>
            </template>
          </el-table-column>

          <el-table-column prop="month_flow"
                           min-width="100"
                           label="月流水"></el-table-column>
          <el-table-column prop="contract_time"
                           label="签约时间"
                           min-width="100"></el-table-column>
          <el-table-column label="主播数据"
                           min-width="220">
            <template slot-scope="scope">
              <p>粉丝数：{{ scope.row.fans_num }}</p>
              <p>作品数：{{ scope.row.work_number }}</p>
              <p>
                直播合作进度：
                <span>{{ scope.row.settlement_status }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           min-width="140"
                           fixed="right">
            <template slot-scope="scope">
              <span class="handle edit"
                    @click="anchorDetail(scope.row.anchor_id)">详情</span>
              <span class="handle del"
                    @click="anchorDelete(scope.row)"
                    v-if="role(['administrator'])">删除</span>
              <br v-else />
              <el-dropdown>
                <span class="el-dropdown-link handle">
                  <span style="color:#FF0000;">编辑或更多</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="role(['administrator', 'manager'])"
                                    @click.native="()=>starAnchorModalFn(scope.row) ">修改来源信息</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </tablePage>
      </el-card>
    </div>
    <router-view></router-view>
    <div class="notice-main-style">
      <el-dialog :visible.sync="showNotice"
                 width="500px"
                 top="10vh"
                 :show-close="false">
        <div slot="title"
             style="height:58px;line-height:58px;text-align:center;font-weight:700;">经纪人注意事项</div>
        <div v-html="notice"
             class="notice-style"></div>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="clickNoticeOk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <customModal :visible="anchorDeleteContent!==null"
                 @handleCancel="anchorDeleteContent=null"
                 @handleOk="req_anchorDelete"
                 title="删除提醒"
                 :content="`确定要删除主播${anchorDeleteContent?anchorDeleteContent.nick_name:''}么？`"></customModal>
    <!-- 星探修改模态框 -->
    <starAnchorModal :visible="starAnchorVisible"
                     :anchor_id="anchor_id"
                     :btnloading="starAnchorBtnLoading"
                     @handleOk="starAnchorOk"
                     @handleCancel="starAnchorCancel" />
  </div>
</template>
 <script>
import tablePage from "components/tablePage";
import brokerSelect from "components/brokerSelect";
import belonger from "components/belonger";
import api from "@/api";
import notice from "../../utils/notice";
import tableHeight from "mixins/tableHeight";
import starAnchorModal from './components/starAnchorModal'
export default {
  components: {
    tablePage,
    brokerSelect,
    belonger,
    starAnchorModal
  },
  data () {
    return {
      loading: true,
      formInline: {
        tick_account: null, // 快手号
        manager: null, //归属人
        tick_number: null, //快手ID
        broker_id: null, // 经纪人
        nick_name: null, // 主播昵称
        page: 1, // 分页
      },
      tableData: [],
      total: 10,
      notice,
      showNotice: false,
      anchorDeleteContent: null,
      starAnchorVisible: false,
      anchor_id: null,
      starAnchorBtnLoading: false
    };
  },
  mixins: [tableHeight],
  created () {
    this.req_list(true);
  },
  methods: {
    anchorDetail (id) {
      this.$router.push({
        path: `anchorList/anchorDetail?anchor_id=${id}`
      })
      localStorage.setItem('anchorSource', JSON.stringify({ name: '主播列表', path: '/anchor/anchorList' }))
    },
    clickNoticeOk () {
      this.showNotice = false;
      this.$router.push({ path: "/anchor/anchorAdd" });
    },
    addClick () {
      this.showNotice = true;
    },
    exportClick () {
      this.$excelDownload(this.formInline, "api/anchor/list/export");
    },
    async _initState (params = {}) {
      this.loading = true;
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.anchorListApi(params);
      if (status_code === 200) {
        this.total = total;
        this.tableData = message;
        this.loading = false;
      }
    },
    currentChange (page) {
      this.formInline.page = page;
      this.req_list();
    },
    //  选择归属人回调
    belongerSeChange (id) {
      this.formInline.manager = id;
    },
    //  选择经纪人回调
    selectTreeChange (id) {
      this.formInline.broker_id = id;
    },
    // 清空经纪人筛选回调
    clearBrokerSelect () {
      this.formInline.broker_id = null;
    },
    // 请求列表
    req_list (refresh) {
      if (refresh) this.formInline.page = 1;
      this._initState(this.formInline);
    },
    // 请求删除主播
    async req_anchorDelete () {
      const { anchor_id } = this.anchorDeleteContent;
      const { status_code, message } = await api.anchorDelete({
        anchor_id,
      });
      if (status_code === 200) {
        this.$message.success(message);
        this.anchorDeleteContent = null;
        this.req_list(true);
      } else {
        this.$message.error(message);
      }
    },
    anchorDelete (item) {
      const { anchor_id, nick_name } = item;
      this.anchorDeleteContent = { anchor_id, nick_name };
    },
    // 显示模态框 传id
    starAnchorModalFn ({ anchor_id }) {
      console.log("xianshi")
      this.starAnchorVisible = true
      this.anchor_id = anchor_id
    },
    // 模态框取消
    starAnchorCancel () {
      this.starAnchorVisible = false
    },
    // 模态框确认
    starAnchorOk (item) {
      this._update_star_anchor(item)
    },
    // 修改星探资料
    async _update_star_anchor (parms) {
      this.starAnchorBtnLoading = true
      const { status_code, message } = await this.$api.updateStarBroker(parms);
      this.starAnchorBtnLoading = false
      if (status_code == 200) {
        this.starAnchorCancel()
        this.$message.success({ message })
        this.req_list(true)
      } else if (status_code === 422) {
        Object.keys(message).map(item => {
          this.$message.error({
            message: message[item]
          })
        })
      } else {
        this.$message.error(message)
      }
    },
  },
  computed: {
    showList () {
      return this.$route.name == "anchorList";
    },
  },
};
</script>
<style lang="less" scoped>
@import "./style.less";
.notice-style {
  height: 400px;
  line-height: 40px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.notice-main-style /deep/ .el-dialog__body {
  padding: 0 20px !important;
}
.notice-main-style /deep/ .el-dialog__header {
  padding: 0 !important;
}
.notice-style::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.edit {
  color: #4183fb;
}
.del {
  color: #666666;
  line-height: 14px;
  border-left: 1px solid #e3e3e3;
  padding-left: 12px;
  margin-left: 12px;
}
.text-red {
  p {
    color: #fa4742 !important;
  }
}
.handle {
  font-size: 14px;
  cursor: pointer;
}
</style>